import React from "react"
import {useDrag} from "react-dnd"

const OptionItem = ({id, value, image, area, setItems}) => {

    const changeOptionArea = (currentOption, areaName) => {
        setItems((prevState) => {

           return prevState.map(e => {
               return {
                   ...e,
                   area: (e.id === currentOption.id) ? (e.area === currentOption.area ? areaName : e.area) : e.area,
               }
           })
        });
    }

    const [{isDragging}, drag] = useDrag({
        item: { area: area, id: id, value: value, setItems: setItems, type: 'OptionItem'},
        type: 'OptionItem',
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (dropResult && dropResult.name === 'One') {
                changeOptionArea(item, 'One')
            } else if( dropResult && dropResult.name === 'Two') {
                changeOptionArea(item, 'Two')
            } else {
                changeOptionArea(item, 'No')
            }

        }
    });

    const opacity = isDragging ? 0.4 : 1;

    const cardStyle = {
        opacity: opacity,
        width: '2em;'
    }

    const imageStyle = {
        width: '100'
    }

    const width = "10"

    let cardClassName = "OptionItem";
    if (value) {
        cardClassName += " card";
    }

    return (
        <div ref={drag} className={cardClassName} style={cardStyle}>
            {
                image
                    ?
                        <img className="card-img-top img-thumbnail" src={image}/>
                    :
                        ''
            }
            {
                value
                    ?
                        <div className="card-body" dangerouslySetInnerHTML={{__html: value}} />
                    :
                        ''
            }

        </div>
    );

}

export default OptionItem;
