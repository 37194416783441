import {useDrop} from "react-dnd";
import React from "react";

const ChoiceAreaOne = ({children, header}) => {
    const [{canDrop, isOver}, drop] = useDrop({
       accept: 'OptionItem',
       drop: () => ({name: 'One'}),
       collect: (monitor) => ({
           isOver: monitor.isOver(),
           canDrop: monitor.canDrop(),
       }),
    });

    return (
        <div className="ChoiceAreaParent col-6 mt-2">
            <h3 dangerouslySetInnerHTML={{__html: header}} />
            <div ref={drop} className="ChoiceArea">
                {children}
            </div>
        </div>
    );
}

export default ChoiceAreaOne;
