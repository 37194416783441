import React from 'react';

class Toolbox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="Toolbox row" />
        )
    }
}

export default Toolbox;
