import React, {useState, useEffect} from 'react';

import { DndProvider, useDrag, useDrop } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";

import ChoiceAreaOne from "./ChoiceAreaOne";
import ChoiceAreaTwo from "./ChoiceAreaTwo";
import OptionItem from "./Option";
import axios from "axios";
import {socket} from "./App";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

let {REACT_APP_STICKITY_URL} = process.env;

const ImageGallery = (props) => {
    let area0 = '';
    let area1 = '';
    let area2 = '';

    let ops = props.options ? props.options : [];

    const isMobile = window.innerWidth < 600;

    const [options, setItems] = useState(ops);
    const [isReload, setIsReload] = useState(true);

    const toastId = "status-message";
    const notify = (message, options) => {
        toast(message, {
            toastId: toastId,
            onClose: () => {
                if (options) {
                    setItems(options);
                }
            }
        })
    }

    const returnOptionsForArea = (areaName) => {

        return options
            .filter((option) => option.area === areaName)
            .map((option) => (
                <OptionItem id={option.id} image={option.image} value={option.value} area={areaName} setItems={setItems}/>
            ));
    }

    useEffect(() => {
        socket.emit("getOptions", {
            gameId: props.gsId
        }, (response) => {
            console.log('getOptions:response.status=', response.status)
        });


        socket.on("updateOptions", ({options, gameId}) => {
            if ( gameId === props.gsId) {
                setItems(options);
            }
        });

        setIsReload(false);

    }, []);

    useEffect(() => {

        area0 =  returnOptionsForArea('No');
        area1 =  returnOptionsForArea('One').map(o => o.props.id);
        area2 =  returnOptionsForArea('Two').map(o => o.props.id);

        if (!isReload) {
            socket.emit("updateOptions", {
                options: options,
                gameId: props.gsId
            });
        }

        if ( area0.length === 0 ) {

            // post choices
            axios.post(REACT_APP_STICKITY_URL+props.gsId,{
                "area1": area1,
                "area2": area2,
            }).then((response) => {
                let wrongAnswers = response.data;
                if (wrongAnswers.length > 0) {
                    let optionsUpdated = options.map(option => {
                        if (wrongAnswers.includes(option.id)) {
                            option.area = 'No';
                        }
                        return option;
                    });
                    let message = props.messageFailure ?? 'Failed';
                    notify(message, optionsUpdated);
                } else {
                    let message = props.messageSuccess ?? 'Congratulations'
                    notify(message);
                }

            }, (error) => {
                console.log(error);
            })
        }
    }, [options]);



     return (
         <div style={{paddingBottom: 22}} className="row">
             <div className="Center col">
                 <div className="Heading"><h1 dangerouslySetInnerHTML={{__html: props.heading}} /></div>

                 <ToastContainer
                     position="top-center"
                     pauseOnFocusLoss={false}
                     pauseOnHover={false}
                 />

                 <div className="row">
                     <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} >
                         <ChoiceAreaOne header={props.header1}>
                             {returnOptionsForArea('One')}
                         </ChoiceAreaOne>
                         <ChoiceAreaTwo header={props.header2}>
                             {returnOptionsForArea('Two')}
                         </ChoiceAreaTwo>

                         <div className="OptionList" id="OptionList">
                            {returnOptionsForArea('No')}
                         </div>

                     </DndProvider>
                 </div>


             </div>

         </div>
     )

 }

export default ImageGallery;
