import React from 'react';
import moment from 'moment';
import {socket} from './App';

class Timer extends React.Component {
    state = {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        const { timeTillDate, timeFormat, gameId } = this.props;
        let then = moment(timeTillDate, timeFormat);

        socket.emit("getTimer", {
            gameId: gameId,
            startTime: moment().unix(),
            countdown: this.props.countdown
        });

        socket.on("Timer", ( {endTime} ) => {
            then = moment.unix(endTime);
        });

        this.interval = setInterval(() => {
            const now = moment();
            const countdown = moment(then - now);

            let days = 0
            let hours = 0
            let minutes = 0
            let seconds = 0

            if ( now < then ) {
                days = countdown.format('D');
                hours = countdown.format('HH');
                minutes = countdown.format('mm');
                seconds = countdown.format('ss');
            }

            this.setState({ days, hours, minutes, seconds });
        }, 1000);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.state.seconds == '00' && this.state.minutes == '00' ) {
            clearInterval(this.interval);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;

        return (
            <div className="Timer row">{minutes}:{seconds}</div>
        )
    }
}

export default Timer;
