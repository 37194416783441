import logo from './logo.svg';
import './App.scss';

import moment from 'moment';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from 'react-router-dom';
import React, { Component, useState } from "react";
import socketIOClient from "socket.io-client";

import ImageGallery from './ImageGallery';
import Infobox from './Infobox';
import Toolbox from './Toolbox';
import Timer from './Timer';
import axios from "axios";


let {REACT_APP_STICKITY_URL, REACT_APP_GSCOLSERVER_URL} = process.env;
var socket = socketIOClient(REACT_APP_GSCOLSERVER_URL);


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gs: [],
      gsId: null,
      countdown: null,
      startTime: null,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if ( id ) {
      REACT_APP_STICKITY_URL += id;
    }

    axios.get(REACT_APP_STICKITY_URL)
        .then( (response) => {

          let { countdown } = response.data;

          document.title += ' - ' + response.data.group + ' - ' + response.data.customer_name;

          this.setState({
            gs: response.data,
            gsId: id,
            countdown: countdown
          });
        })
        .catch(error => {
          console.log("ERROR::", error.message)
        });


  }

  //function App() {
  render() {

    const info = this.state.gs.info;
    let timeTillDate = 0;
    const gsId = this.state.gsId;

    const now = moment();

    let imageGallery = <Infobox />

    if (this.state.gs.id) {
      timeTillDate = this.state.gs.countdown > 0 ? now.add(this.state.gs.countdown, 'm') : 0;
      imageGallery = <ImageGallery
          gsId={gsId}
          heading={this.state.gs.header}
          options={this.state.gs.options}
          header1={this.state.gs.choice_area_header_1}
          header2={this.state.gs.choice_area_header_2}
          messageSuccess={this.state.gs.message_check_success}
          messageFailure={this.state.gs.message_check_failure}
          onchange={(e, selectedImages) => this.saveSelectedImages(e, selectedImages)}/>
    }

    return (
        <div className="App container-fluid">

          <div className="Header row rounded-top align-content-center text-center">&nbsp;</div>
          <div className="Main row">
            <div className="Tools col col-lg-1">
              <Infobox info={info} />
              <Toolbox onClickSubmit={(e) => this.handleClickSubmit()} />
              {
                timeTillDate != 0
                    ?
                    <Timer
                        gameId={this.state.gsId}
                        countdown={this.state.countdown}
                        timeTillDate={timeTillDate}
                        timeFormat="MM DD YYYY, h:mm a" />
                    :
                    null
              }

            </div>
            <div className="Gallery col col-lg-11">
              {imageGallery}
            </div>
          </div>

        </div>
    );
  }
}

export default withRouter(App);
export {socket};
