import React from "react";
import {useDrop} from "react-dnd";

const ChoiceAreaTwo = ({children, header}) => {
    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'OptionItem',
        drop: () => ({name: 'Two'}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div className="ChoiceAreaParent col-6 mt-2">
            <h3 dangerouslySetInnerHTML={{__html: header}} />
            <div ref={drop} className="ChoiceArea">
                {children}
            </div>
        </div>
    );
}

export default ChoiceAreaTwo;
